<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('cities.items')" />

      <main-table
        :fields="fields"
        :list_url="'admin/city'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('cities.create')"
        createRouteName="generalSettings.cityCreate"
        :excelFields="excelFields"
        excelName="cities"
        :downloadSampleStatus="true"
        downloadSampleUrl="city/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="city/excel"
      />
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import CityFilter from "../models/CityFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new CityFilter(),
      fields: [
        this.$t("global.selected"),
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        {
          key: "country.name",
          label: this.$t("countries.item"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "generalSettings.cityView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "generalSettings.cityUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("cities.item"),
              textContent: "name",
              url: "admin/city",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("countries.item")]: "countryName",
        [this.$t("table.createdAt")]: "created_at",
      },
    };
  },
  props: {
    countryId: { default: "" },
  },
  mounted() {
    core.index();
  },
  created() {
    this.filter.country_id = this.countryId;
  },
};
</script>
