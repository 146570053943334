import GeneralFilter from '../../../../Model/GeneralFilter'

export default class CityFilter extends GeneralFilter {
  constructor () {
    super()
    this.setInitialValue()
  }

  setInitialValue () {
    this.setFilterInitialValue()
    this.country_id = ''
  }
}
